import React, { useState, useEffect } from 'react';
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'; 
import './security.scss';
import url from "../../components/endpoint";

const ChangePassword = ({ onChange }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [strength, setStrength] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handlePasswordUpdate = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      toast.error('New password and confirmation do not match.');
      return;
    }

    toast.promise(
      axios.post(`${url}/api/User/update-password`, { currentPassword, newPassword }, { 
        withCredentials: true, 
        headers: { Authorization: `Bearer ` } 
      }),
      {
        pending: 'Processing password change...',
        success: () => {
          resetPasswordFields();
          return 'Password updated successfully!';
        },
        error: (error) => {
          console.error(error);
          const message = error.response?.data?.message || 'Failed to update password. Please try again.';
          return message;
        },
      }
    );
  };

  const resetPasswordFields = () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    onChange();
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setNewPassword(password);
    setStrength(checkPasswordStrength(password));
  };

  const checkPasswordStrength = (password) => {
    const criteriaMet = [
      password.length >= 8,
      /\d/.test(password),
      /[a-z]/.test(password),
      /[A-Z]/.test(password),
      /[!@#$%^&*]/.test(password)
    ].filter(Boolean).length;

    return criteriaMet <= 2 ? 'Weak' : criteriaMet === 3 ? 'Medium' : 'Strong';
  };

  return (
    <div className="security-section">
      <h3>Change Password</h3>
      <form onSubmit={handlePasswordUpdate}>
        <div className="form-group">
          <label>Current Password</label>
          <input type="password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} required />
        </div>
        <div className="form-group">
          <label>New Password</label>
          <input 
            type={passwordVisible ? 'text' : 'password'} 
            value={newPassword} 
            onChange={handlePasswordChange} 
            required 
          />
          <p className={`password-strength ${strength.toLowerCase()}`}>Strength: {strength}</p>
        </div>
        <div className="form-group">
          <label>Confirm New Password</label>
          <input 
            type={passwordVisible ? 'text' : 'password'} 
            value={confirmPassword} 
            onChange={(e) => setConfirmPassword(e.target.value)} 
            required 
          />
        </div>
        <button type="submit" className="btn btn-primary">Change Password</button>
      </form>
    </div>
  );
};

const TwoFactorAuth = ({ is2FAEnabled, setIs2FAEnabled }) => {
  const toggle2FA = async () => {
    const new2FAState = !is2FAEnabled;
    const actionMessage = new2FAState ? 'enabling' : 'disabling';

    toast.promise(
      axios.post(`${url}/api/User/enable-2fa`, new2FAState, {
        withCredentials: true,
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ` 
        }
      }),
      {
        pending: `You are currently ${actionMessage} two-factor authentication...`,
        success: () => {
          setIs2FAEnabled(new2FAState);
          return `Two-factor authentication ${new2FAState ? 'enabled' : 'disabled'} successfully!`;
        },
        error: ({ response }) => {
          console.error(response.data);
          return response.data?.message || `Failed to ${actionMessage} two-factor authentication. Please try again.`;
        },
      }
    );
  };

  return (
    <div className="security-section">
      <h3>Two-Factor Authentication (2FA)</h3>
      <p>Enhance your account security by enabling 2FA. You will need to provide an extra code when logging in.</p>
      <button 
        onClick={toggle2FA} 
        className={`btn ${is2FAEnabled ? 'btn-danger' : 'btn-success'}`}>
        {is2FAEnabled ? 'Disable 2FA' : 'Enable 2FA'}
      </button>
    </div>
  );
};

const SecurityLogs = ({ loading, securityLogs }) => (
  <div className="security-section">
    <h3>Security Logs</h3>
    <ul className="security-logs">
      {loading ? (
        Array(3).fill().map((_, index) => (
          <li key={index}>
            <Skeleton width="100%" height="20px" />
          </li>
        ))
      ) : (
        securityLogs.map((log, index) => (
          <li key={index}>
            <span className="log-date">{log.date}</span> - <span className="log-activity">{log.activity}</span>
          </li>
        ))
      )}
    </ul>
  </div>
);

// ActiveSessions Component
const ActiveSessions = ({ activeSessions, logoutSession }) => (
  <div className="security-section">
    <h3>Active Sessions</h3>
    <ul>
      {activeSessions.map((session, index) => (
        <li key={index}>
          {session.device} ({session.location}) {session.active ? '(Active)' : '(Logged out)'}
          {session.active && (
            <button onClick={() => logoutSession(session.device)} className="btn btn-danger">Logout</button>
          )}
        </li>
      ))}
    </ul>
  </div>
);

// Main Security Component
const Security = ({ showSidebar, active, closeSidebar }) => {
  const [is2FAEnabled, setIs2FAEnabled] = useState(false);
  const [securityLogs, setSecurityLogs] = useState([]);
  const [activeSessions, setActiveSessions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSecurityLogs([
        { date: '2024-10-13', activity: 'Login from Chrome, New York' },
        { date: '2024-10-12', activity: 'Password changed' },
        { date: '2024-10-10', activity: 'Login from Firefox, Los Angeles' }
      ]);
      setActiveSessions([
        { device: 'Chrome', location: 'New York', active: true },
        { device: 'Firefox', location: 'Los Angeles', active: false },
      ]);
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const logoutSession = (device) => {
    setActiveSessions(prevSessions => 
      prevSessions.map(session => 
        session.device === device ? { ...session, active: false } : session
      )
    );
    alert(`Logged out from ${device}`);
  };

  return (
    <div className="security-wrapper">
      <Sidebar active={active} closeSidebar={closeSidebar} />
      <div className="security-container">
        <Navbar showSidebar={showSidebar} />
        <h2 className="security-title">Account Security</h2>
        
        <ChangePassword onChange={() => setLoading(true)} />
        <TwoFactorAuth is2FAEnabled={is2FAEnabled} setIs2FAEnabled={setIs2FAEnabled} />
        <SecurityLogs loading={loading} securityLogs={securityLogs} />
        <ActiveSessions activeSessions={activeSessions} logoutSession={logoutSession} />
      </div>
      <ToastContainer />
    </div>
  );
};

export default Security;
