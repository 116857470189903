import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateBalance } from '../../redux/actions/userActions'; 
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './betModal.scss';
import axios from 'axios';
import url from '../../components/endpoint';

const BetModal = ({ isOpen, onClose, gameId, min, token }) => {
    const [betAmount, setBetAmount] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const { balance } = useSelector((state) => state.user.userData) || {};
    const dispatch = useDispatch();

    const validateBetAmount = useCallback((value) => {
        const parsedValue = parseFloat(value);

        if (Number.isNaN(parsedValue) || parsedValue <= 0) {
            return 'Invalid amount.';
        }
        if (parsedValue < parseFloat(min)) {
            return `Minimum amount is R${min}`;
        }
        if (parsedValue > parseFloat(balance)) {
            return 'Insufficient balance, please deposit.';
        }
        return '';
    }, [min, balance]);

    const handleChange = (e) => {
        const value = e.target.value;
        setBetAmount(value);
        const error = validateBetAmount(value);
        setErrorMessage(error);
        setIsValid(!error); 
    };

    const getGameName = (id) => {
        switch (id) {
            case 1: return 'wordsearch';
            case 2: return 'penaltyshootout';
            case 3: return 'cupguess';
            default: return '';
        }
    };

    const handleSubmit = async () => {
        const betValue = parseFloat(betAmount);
        const game = getGameName(gameId);

        if (isValid) {
            const newBalance = parseFloat(balance) - betValue;
            dispatch(updateBalance(newBalance));
        }

        setLoading(true);
        
        toast.promise(
            axios.get(`${url}/api/Games/games/${game}/${betAmount}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }),
            {
                pending: 'Setting up your game...',
                success: {
                    render({ data }) {

                      if(data.status === 200){
                        setLoading(false);

                        const link = data.data.link;

                        window.location.href = `${link}`
                      
                        return 'Game started! Redirecting...';
                    }
                  }
                },
                error: {
                    render({ }) {
                     
                        setLoading(false);
                        
                        return 'Error starting game!';
                    }
                }
            }
        )

        
    };

    const resetForm = () => {
        setBetAmount('');
        setIsValid(false);
        setErrorMessage('');
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="bet-modal-overlay">
            <div className="bet-modal">
            <ToastContainer />
                <h2>Place Your Bet</h2>
                {errorMessage && <p className="error-message">{errorMessage}</p>} 
                <input
                    type="number"
                    value={betAmount}
                    onChange={handleChange}
                    placeholder="Enter bet amount"
                    className={`bet-input ${isValid ? 'valid' : 'invalid'}`}
                />
                <div className="modal-buttons">
                    <button 
                        className="start-button" 
                        onClick={handleSubmit} 
                        disabled={!isValid || loading} 
                    >
                        {loading ? 'Starting...' : 'Start'}
                    </button>
                    <button className="cancel-button" onClick={resetForm}>
                        Cancel
                    </button>
                </div>
            </div>
           
        </div>
    );
};

export default BetModal;
