import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData, clearError } from '../../redux/actions/userActions';
import './Navbar.scss';
import Error from "../../Pages/Modals/ErrorModal";

const Navbar = () => {
    const dispatch = useDispatch();
    const { userData, loading, error } = useSelector((state) => state.user);
    
    useEffect(() => {
     if (!userData) {
                dispatch(fetchUserData());
            }

    }, [dispatch, userData]);  

    

    const handleCloseError = () => {
        dispatch(clearError());
    };

    return (
        <>
            <header className="navbar">
                <div className="navbar-content">
                    <div className="balance">
                    
                    <div className="number"> {loading ? "Loading..." : userData ? `R${userData.balance}` : ''}</div>
                    </div>

                    <div className="account-number">
                        <div className="label">Acc No:</div>
                        <div className="number">{userData?.accountNumber || ''}</div>
                    </div>
                </div>
            </header>

            {error && <Error errorMessage={error} isOpen={true} onClose={handleCloseError} />}
        </>
    );
};

export default Navbar;
