import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify"; 
import "react-toastify/dist/ReactToastify.css"; 
import "./Withdraw.scss";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import Success from "../Modals/Success";
import url from '../../components/endpoint';

const Withdraw = ({ showSidebar, active, closeSidebar }) => {
  const [formData, setFormData] = useState({
    amount: "",
    account: "",
    bank: "",
    email: "",
    password: "",
  });
  const [csrfToken, setCsrfToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [amountError, setAmountError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [bankError, setBankError] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isButtonPaypalDisabled, setIsButtonPaypalDisabled] = useState(true);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const token = localStorage.getItem("token");
  const [withdrawalMethod, setWithdrawalMethod] = useState("");

  const handleWithdrawMethod = (method) => {
    setWithdrawalMethod(method);
  };

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await axios.get(`${url}/auth/csrfToken`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setCsrfToken(response.data.csrfToken);
      } catch {
        toast.error("Unable to fetch CSRF token, please refresh.");
      }
    };
    fetchCsrfToken();
  }, [token]);

  useEffect(() => {
    const { amount, account, bank, password } = formData;
    setIsButtonDisabled(
      !amount ||
      (!account && !formData.email) || 
      (!bank && !formData.email) || 
      !password ||
      amountError ||
      passwordError ||
      bankError
    );
  }, [formData, amountError, passwordError, bankError]);

  useEffect(() => {
    const { amount, email, password } = formData;
    setIsButtonPaypalDisabled(!amount || !email || !password);
  }, [formData]);

  const handleChange = (e) => {
    setPasswordError("");
    setBankError("");
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));

    if (name === "amount") {
      setAmountError(isNaN(value) || value <= 0 ? "Invalid withdrawal amount" : "");
    }
  };

  const handleWithdraw = async () => {
    const { amount, account, bank, password } = formData;

    if (!amount || isNaN(amount) || amount <= 0) {
      toast.error("Invalid withdrawal amount");
      return;
    }
    if (!password) {
      toast.error("Please enter your password.");
      return;
    }
    if (amount < 200) {
      setAmountError("Minimum withdrawal amount is R200.");
      return;
    }

    const requestBody = { amount: parseFloat(amount), account, bank, password };

    setLoading(true);
    toast.promise(
      axios.post(`${url}/Bankwithdraw`, requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRF-Token": csrfToken,
        },
      }),
      {
        pending: "Processing your withdrawal...",
        success: (response) => {
          setIsSuccessModalOpen(true);
          setFormData({ amount: "", account: "", bank: "", password: "", email: formData.email });
          return `Withdrawal successful! New balance: R${response.data.newBalance}`;
        },
        error: (error) => {
          const responseError = error.response?.data;
          if (responseError) {
            const errors = responseError.errors?.map(err => err.msg).join(", ") || responseError.PasswordError || responseError.NotAuthorisedError || responseError.UserError || responseError.MinimumError || responseError.BalanceError;
            return errors || "Error processing withdrawal.";
          }
          return "Error processing withdrawal.";
        },
      }
    )
    .finally(() => setLoading(false));
  };

  const handleWithdrawPaypal = async () => {
    const { amount, email, password } = formData;

    if (!amount || isNaN(amount) || amount <= 0) {
      toast.error("Invalid withdrawal amount");
      return;
    }
    if (!email) {
      toast.error("Please enter your PayPal email.");
      return;
    }
    if (amount < 200) {
      toast.error("Minimum withdrawal amount is R200.");
      return;
    }
    if (amount > 50000) {
      toast.error("Maximum withdrawal amount is R50000.");
      return;
    }
    if (!password) {
      toast.error("Please enter your password.");
      return;
    }

    const requestBody = { amount: parseFloat(amount), email, password };

    setLoading(true);
    toast.promise(
      axios.post(`${url}/Paypalwithdraw`, requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRF-Token": csrfToken,
        },
      }),
      {
        pending: "Processing your withdrawal...",
        success: (response) => {
          setFormData({ amount: "", account: "", bank: "", password: "", email: "" });
          return `Withdrawal successful! Check your email. New balance: R${response.data.newBalance}`;
        },
        error: (error) => {
          const responseError = error.response?.data;
          return responseError?.error || "Error processing withdrawal.";
        },
      }
    )
    .finally(() => setLoading(false));
  };

  return (
    <div className="withdraw">
      <ToastContainer />
      <Sidebar active={active} closeSidebar={closeSidebar} />
      <div className="withdraw_container">
        <Navbar showSidebar={showSidebar} />
        <div className="content">
          <div className="middle">
            <div className="left">
              <h3>Withdraw Funds</h3>
              <div className="withdrawal-buttons">
                {withdrawalMethod === "" && (
                  <>
                    <button
                      className={`form_btn ${withdrawalMethod === "bank" ? "active" : ""}`}
                      onClick={() => handleWithdrawMethod("bank")}
                    >
                      Bank Withdrawal
                    </button>
                    <button
                      className={`form_btn ${withdrawalMethod === "paypal" ? "active" : ""}`}
                      onClick={() => handleWithdrawMethod("paypal")}
                    >
                      PayPal Withdrawal
                    </button>
                  </>
                )}
              </div>
  
              {withdrawalMethod === "bank" && (
                <>
                  <div>
                    <label>Withdraw Amount</label>
                    <br />
                    <input
                      type="number"
                      name="amount"
                      value={formData.amount}
                      onChange={handleChange}
                      inputMode="numeric"
                    />
                    {amountError && <p className="error-message">{amountError}</p>}
                  </div>
                  <div>
                    <label>Account Number</label>
                    <br />
                    <input
                      type="text"
                      name="account"
                      value={formData.account}
                      onChange={handleChange}
                      inputMode="numeric"
                    />
                  </div>
                  <div>
                    <label>Password</label>
                    <br />
                    <input
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      inputMode="text"
                    />
                    {passwordError && <p className="error-message">{passwordError}</p>}
                  </div>
                  <div className="right">
                    <div className="dropdown_container">
                      <span>Select Bank:</span>
                      <br />
                      <select
                        name="bank"
                        className="dropdown"
                        value={formData.bank}
                        onChange={handleChange}
                      >
                        <option value="" disabled>Select Bank</option>
                        <option value="FNB">FNB</option>
                        <option value="ABSA">ABSA</option>
                        <option value="NEDBANK">NEDBANK</option>
                        <option value="STANDARD BANK">STANDARD BANK</option>
                        <option value="CAPITEC">CAPITEC</option>
                      </select>
                      {bankError && <p className="error-message">{bankError}</p>}
                    </div>
                    <button className="form_btn" onClick={handleWithdraw} disabled={isButtonDisabled || loading}>
                      Withdraw
                    </button>
                  </div>
                </>
              )}

              {withdrawalMethod === "paypal" && (
                <>
                  <div>
                    <label>Withdraw Amount</label>
                    <br />
                    <input
                      type="number"
                      name="amount"
                      value={formData.amount}
                      onChange={handleChange}
                      inputMode="numeric"
                    />
                    {amountError && <p className="error-message">{amountError}</p>}
                  </div>
                  <div>
                    <label>PayPal Email</label>
                    <br />
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      inputMode="text"
                    />
                  </div>
                  <div>
                    <label>Password</label>
                    <br />
                    <input
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      inputMode="text"
                    />
                    {passwordError && <p className="error-message">{passwordError}</p>}
                  </div>
                  <div className="right">
                    <button className="form_btn" onClick={handleWithdrawPaypal} disabled={isButtonPaypalDisabled || loading}>
                      Withdraw via PayPal
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Success isOpen={isSuccessModalOpen} closeModal={() => setIsSuccessModalOpen(false)} />
    </div>
  );
};

export default Withdraw;
