import React, { useEffect, useState } from "react";
import "./Profile.scss";
import "../../App.scss";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import { FiLoader } from "react-icons/fi";
import DeleteModal from "../Modals/DeleteModal";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";

const Profile = ({ showSidebar, active, closeSidebar }) => {
  const [loading, setLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const userData = useSelector((state) => state.user.userData) || {};
  const { fullName, surname, verified, defaultProfilePictureUrl } = userData;
  const loadingFromStore = useSelector((state) => state.user.loading);

  useEffect(() => {
    setLoading(loadingFromStore);
  }, [loadingFromStore]);

  return (
    <div className="profile">
      {loading && <LoadingSpinner />}
      <Sidebar active={active} closeSidebar={closeSidebar} />
      <div className="profile_container">
        <Navbar showSidebar={showSidebar} />
        <UserInfo 
          fullName={fullName} 
          surname={surname} 
          verified={verified} 
          defaultProfilePictureUrl={defaultProfilePictureUrl} 
        />
        <ActionButtons />
        <ManagementOptions setIsDeleteModalOpen={setIsDeleteModalOpen} />
      </div>
      {isDeleteModalOpen && (
        <DeleteModal onClose={() => setIsDeleteModalOpen(false)} />
      )}
    </div>
  );
};

const LoadingSpinner = () => (
  <div className="overlay">
    <FiLoader className="loading-spinner" />
  </div>
);

const UserInfo = ({ fullName, surname, verified, defaultProfilePictureUrl }) => (
  <div className="top">
    <div className="user_info">
      <div className="profile_pic">
        <img src={defaultProfilePictureUrl} alt="User Profile" />
      </div>
      <div className="text">
        <span>Fullname:</span>
        <div className="text_item">{fullName || 'Loading...'}</div>
        <span>Surname:</span>
        <div className="text_item">{surname || 'Loading...'}</div>
        <span>Status:</span>
        <div className="text_item">{verified ? verified : 'Not Verified'}</div>
      </div>
    </div>
  </div>
);

const ActionButtons = () => (
  <>
    <Link to="/refer">
      <button className="form_btn_new">Referral</button>
    </Link>
    <Link to="/chat">
      <button className="form_btn_new">Chat to us</button>
    </Link>
  </>
);

const ManagementOptions = ({ setIsDeleteModalOpen }) => (
  <div className="management_section">
    <div className="management_options">
      <Link to="/edit-profile" className="management_option">
        <span>Edit Profile</span>
      </Link>
      <Link to="/security" className="management_option">
        <span>Security</span>
      </Link>
      <Link to="/verification" className="management_option">
        <span>Account Verification</span>
      </Link>
      <button className="form_btn_new" onClick={() => setIsDeleteModalOpen(true)}>
        Delete Account
      </button>
    </div>
  </div>
);

export default Profile;
