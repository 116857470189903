import React, { useState } from 'react';
import { Button, InputGroup, FormControl } from 'react-bootstrap';
import './refer.scss'; 
import { useSelector } from 'react-redux';
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import { motion } from 'framer-motion';
import "../../App.scss";

const ReferralPage = ({ showSidebar, active, closeSidebar }) => {
  const [referralLink, setReferralLink] = useState('');
  const { accountNumber } = useSelector((state) => state.user.userData) || {};

  const generateReferralLink = () => {
    if (accountNumber) {
      const link = `https://Play929.com/?r=${accountNumber}`;
      setReferralLink(link);
    } else {
      alert('Account number not found. Please check your profile.');
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralLink)
      .then(() => alert('Copied to clipboard'))
      .catch((err) => console.error('Failed to copy:', err));
  };

  const fadeInAnimation = {
    hidden: { opacity: 0, y: 20 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.2 } 
    }),
  };

  return (
    <div className="refer">
      <Sidebar active={active} closeSidebar={closeSidebar} />
      <div className="container">
        <Navbar showSidebar={showSidebar} />
        
        <AnimatedText 
          text="You earn the same amount as the user you referred deposited!" 
          customDelay={2} 
          animation={fadeInAnimation}
        />
        
        <AnimatedText 
          text="Join our referral program to earn rewards by inviting your friends! Simply generate a referral link and share it. When your friends sign up using your link and make their first deposit, you both benefit!" 
          customDelay={3} 
          animation={fadeInAnimation}
        />

        {!referralLink ? (
          <motion.div initial="hidden" animate="visible" variants={fadeInAnimation} custom={4}>
            <Button className="form_btn" variant="primary" onClick={generateReferralLink}>
              Generate Referral Link
            </Button>
          </motion.div>
        ) : (
          <ReferralInput 
            referralLink={referralLink} 
            copyToClipboard={copyToClipboard} 
          />
        )}

        <TestimonialsSection fadeInAnimation={fadeInAnimation} />
        <FAQsSection fadeInAnimation={fadeInAnimation} />
      </div>
    </div>
  ); 
};

const AnimatedText = ({ text, customDelay, animation }) => (
  <motion.p
    className="instructions"
    initial="hidden"
    animate="visible"
    variants={animation}
    custom={customDelay}
  >
    {text}
  </motion.p>
);

const ReferralInput = ({ referralLink, copyToClipboard }) => (
  <InputGroup className="mb-3 mt-3">
    <FormControl
      readOnly
      value={referralLink}
      className="referral-input"
    />
    <Button className='form_btn_new' variant="outline-secondary" onClick={copyToClipboard}>
      Copy Referral Link
    </Button>
  </InputGroup>
);

const TestimonialsSection = ({ fadeInAnimation }) => (
  <motion.div
    className="testimonials"
    initial="hidden"
    animate="visible"
    variants={fadeInAnimation}
    custom={5}
  >
    <h2>What Our Users Say</h2>
    <div className="testimonial">
      <p>"I earned a lot from the referral program! It's so easy to use!"</p>
    </div>
    <div className="testimonial">
      <p>"Sharing my link was a breeze, and I got my rewards quickly!"</p>
    </div>
  </motion.div>
);

const FAQsSection = ({ fadeInAnimation }) => (
  <motion.div
    className="faq"
    initial="hidden"
    animate="visible"
    variants={fadeInAnimation}
    custom={6}
  >
    <h2>Frequently Asked Questions</h2>
    <FAQItem question="How does the referral program work?" answer="You earn rewards for every user that signs up using your referral link." />
    <FAQItem question="Is there a limit to how many referrals I can make?" answer="No, you can refer as many friends as you like!" />
    <FAQItem question="When will I receive my rewards?" answer="Rewards are credited to your account once the referred user completes their first deposit." />
  </motion.div>
);

const FAQItem = ({ question, answer }) => (
  <div className="faq-item">
    <div>{question}</div>
    <div className="faq-answer">{answer}</div>
  </div>
);

export default ReferralPage;
