import { createStore, combineReducers, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk'; 
import userReducer from './reducers/userReducer';

const rootReducer = combineReducers({
  user: userReducer,
});

const middleware = [thunk];


const store = createStore(
  rootReducer,
  applyMiddleware(...middleware)
);

export default store;
