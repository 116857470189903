import React, { useState } from 'react';
import './DeleteModal.scss';
import { FaSpinner } from "react-icons/fa";
import { useSelector } from 'react-redux';
import url from "../../components/endpoint";


const DeleteModal = ({ onClose }) => {
  const [loading, setLoading] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [error, setError] = useState(null);
  const { exclamationImageUrl} = useSelector((state) => state.user.userData) || {};

  const handleDeleteAccount = async () => {
    setLoading(true);
    setError(null); 

    try {
      const token = localStorage.getItem('token'); 
      const response = await fetch(`${url}/api/Account/delete-account`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, 
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete account. Please try again later.');
      }

      setLoading(false);
      setDeleted(true);

    
      window.location.href = 'https://auth.play929.com';
      
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  };

  return (
    <div className="delete-modal-overlay">
      <div className="delete-modal">
        {!deleted ? (
          <>
            {loading ? (
              <div className="overlay">
                <FaSpinner className="loading-spinner" />
              </div>
            ) : (
              <>
                <p>Are you sure you want to delete your account?</p>
                {error && <p className="error-message">{error}</p>}
                <div className="modal-buttons">
                  <button className="confirm-btn" onClick={handleDeleteAccount} disabled={loading}>
                    Yes, Delete
                  </button>
                  <button className="cancel-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            )}
          </>
        ) : (
          <div className="deleted-message">
            <img src={exclamationImageUrl} alt="Exclamation" />
            <h3>Account Deleted</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default DeleteModal;
