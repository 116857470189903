import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import { motion } from 'framer-motion'; 
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import "./Home.scss";
import url from "../../components/endpoint";
import BetModal from "../Modals/betModal";

const Home = ({ showSidebar, active, closeSidebar }) => {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedGameId, setSelectedGameId] = useState(null);
  const [gameMin, setGameMin] = useState(null);
  
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await axios.get(`${url}/api/games/games`);
        setGames(response.data.games);
      } catch (error) {
        console.error('Error fetching games:', error);
      } finally {
        setLoading(false); 
      }
    };

    fetchGames();
  }, []);

  const handleOpenModal = (gameId, min) => {
    setSelectedGameId(gameId); 
    setGameMin(min);
    setModalOpen(true); 
  };

  const handleCloseModal = () => {
    setSelectedGameId(null); 
    setModalOpen(false); 
  };

  return (
    <div className="home">
      <Sidebar active={active} closeSidebar={closeSidebar} />
      <div className="home_container">
        <Navbar showSidebar={showSidebar} />

        <div className="scrollview">
          {loading ? renderSkeletons() : renderGameCards()}
        </div>

        <BetModal 
          isOpen={isModalOpen} 
          onClose={handleCloseModal} 
          gameId={selectedGameId} 
          min={gameMin}
          token={token}
        />
      </div>
    </div>
  );

  function renderSkeletons() {
    return (
      <>
        {[...Array(4)].map((_, index) => (
          <div className="skeleton_card" key={index}>
            <Skeleton height={150} width={250} className="skeleton_image" />
            <Skeleton count={2} />
          </div>
        ))}
      </>
    );
  }

  function renderGameCards() {
    return games.map((game) => (
      <motion.div 
        key={game.id} 
        className="game_card"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <img src={game.image} alt={game.name} className="game_image" />
        <div className="game_info">
          <h3>{game.name}</h3>
          <p>Min: R{game.min}</p>
          <button 
            className="play_button" 
            onClick={() => handleOpenModal(game.id, game.min)} 
          >
            Play
          </button>
        </div>
      </motion.div>
    ));
  }
};

export default Home;
