import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Sidebar from '../../components/Sidebar/Sidebar';
import Navbar from '../../components/Navbar/Navbar';
import { updateProfilePicture, updateName, updateSurName } from '../../redux/actions/userActions';

import './EditProfile.scss';
import "../../App.scss";

const EditProfile = React.memo(({ showSidebar, active, closeSidebar }) => {
  const [localFullName, setLocalFullName] = useState('Jane Doe');
  const [localSurname, setLocalSurname] = useState('Smith');
  const [editingField, setEditingField] = useState(null);
  const { fullName, surname, defaultProfilePictureUrl } = useSelector((state) => state.user.userData) || {};
  const dispatch = useDispatch();
  const API_BASE_URL = 'https://api.play929.com';
  const [profilePic, setProfilePic] = useState(defaultProfilePictureUrl);

  useEffect(() => {
    if (fullName) setLocalFullName(fullName);
    if (surname) setLocalSurname(surname);
  }, [fullName, surname]);

  const handleInputChange = (event, field) => {
    const value = event.target.value;
    if (field === "name") setLocalFullName(value);
    else if (field === "surname") setLocalSurname(value);
  };

  const handleEditClick = (field) => setEditingField(field);

  const handleProfilePicChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5000000) {
        toast.error("Image size must be less than 5MB.");
        return;
      }
      if (!["image/jpeg", "image/png", "image/jpg"].includes(file.type)) {
        toast.error("Only JPEG or PNG images are allowed.");
        return;
      }
      await uploadProfilePicture(file);
    }
  };

  const uploadProfilePicture = useCallback(async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error("User is not authenticated.");

      const { data: { url, message } } = await toast.promise(
        axios.post(`${API_BASE_URL}/api/profile/upload-profile-picture`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }),
        {
          pending: "Uploading profile picture...",
          success: "Profile picture uploaded successfully!",
          error: "Failed to upload profile picture. Try again!",
        }
      );

      setProfilePic(url);
      dispatch(updateProfilePicture(url));
      toast.success(message);
    } catch (error) {
      console.error("Upload Error:", error);
      toast.error("Error uploading profile picture. Please try again.");
    }
  }, [dispatch, API_BASE_URL]);

  const handleBlur = (field) => {
    setEditingField(null);
    if (field === 'name') {
      dispatch(updateName(localFullName));
    } else if (field === 'surname') {
      dispatch(updateSurName(localSurname));
    }
  };

  return (
    <div className="EditProfile">
      <ToastContainer />
      <Sidebar active={active} closeSidebar={closeSidebar} />
      <div className="EditProfile_container">
        <Navbar showSidebar={showSidebar} />
        <div className="top">
          <div className="user_info">
            <div className="profile_section">
              <div className="profile_pic">
                <img src={profilePic} alt="Profile" />
              </div>
              <label className="edit_button">
                Change
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleProfilePicChange}
                />
              </label>
            </div>

            <EditableTextField
              label="Full Name:"
              value={localFullName}
              onChange={(e) => handleInputChange(e, 'name')}
              isEditing={editingField === 'name'}
              onEditClick={() => handleEditClick('name')}
              onBlur={() => handleBlur('name')}
            />

            <EditableTextField
              label="Surname:"
              value={localSurname}
              onChange={(e) => handleInputChange(e, 'surname')}
              isEditing={editingField === 'surname'}
              onEditClick={() => handleEditClick('surname')}
              onBlur={() => handleBlur('surname')}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

const EditableTextField = React.memo(({ label, value, onChange, isEditing, onEditClick, onBlur }) => (
  <div className="text_section">
    <span>{label}</span>
    {isEditing ? (
      <input
        className="input"
        type="text"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    ) : (
      <div className="text_item">{value || 'Loading...'}</div>
    )}
    <button className="edit_button" onClick={onEditClick}>Edit</button>
  </div>
));

export default EditProfile;
