import axios from 'axios';
import url from '../../components/endpoint';


export const fetchUserData = () => async (dispatch) => {
    try {
        dispatch({ type: 'USER_FETCH_REQUEST' });

        const response = await axios.get(`${url}/api/User/data`, {
            withCredentials: true,
            headers: {
                Authorization: `Bearer ${url}`, 
            },
            
        });

        dispatch({ type: 'USER_FETCH_SUCCESS', payload: response.data });
    } catch (error) {
        console.log(error);
        dispatch({ type: 'USER_FETCH_FAILURE', payload: error.message });
    }
};


export const updateBalance = (newBalance) => {
    return {
        type: 'UPDATE_BALANCE',
        payload: newBalance,
    };
};

export const updateProfilePicture = (file) => {
    return {
        type: 'UPDATE_PROFILE_PICTURE',
        payload: file,
    };
};

export const updateName = (name) => {
    return {
        type: 'UPDATE_NAME',
        payload: name,
    };
};

export const updateSurName = (surname) => {
    return {
        type: 'UPDATE_SURNAME',
        payload: surname,
    };
};


export const clearError = () => {
    return {
        type: 'USER_FETCH_CLEAR_ERROR',
    };
};
