import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiLoader } from "react-icons/fi";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import "./wallet.scss";
import "../../App.scss";
import { useSelector } from 'react-redux';

const Wallet = ({ showSidebar, active, closeSidebar }) => {
  const [loading, setLoading] = useState(false);
  
  const userData = useSelector((state) => state.user.userData);
  const loadingFromStore = useSelector((state) => state.user.loading);

  useEffect(() => {
    setLoading(loadingFromStore);
  }, [loadingFromStore]);



  return (
    <div className="wallet">
      <Sidebar active={active} closeSidebar={closeSidebar} />

      <div className="wallet_container">
        <Navbar showSidebar={showSidebar} />

        <div className="account_info">
          {loading && (
            <div className="overlay">
              <FiLoader className="loading-spinner" />
            </div>
          )}

          <span>Account Balance:</span>
            <div className="balance">{`R${userData.balance.toString()}`}</div>

          <Link className="form_btn" to="/withdraw">
            Withdraw
          </Link>
          <Link className="form_btn" to="/deposit">
            Deposit
          </Link>
        </div>
        
       
      </div>
    </div>
  );
};

export default Wallet;
